.blog-author-data {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-top: -30px;
}
.social-share-btn {
  position: fixed;
  width: 1330px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.author-image-with-name {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.top-banner {
  max-width: 1500px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.top-banner img {
  width: 100%;
}
.blog-dtil-page {
  padding-bottom: 40px;
}
.social-share-btn .copy-area {
  text-align: center;
  width: 64px;
  height: 64px;
  background: #0075a8;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

@media (max-width: 1199px) {
  .social-share-btn {
    width: 930px;
    max-width: 97%;
  }
}

.wrapper.blog-dtil-page {
  max-width: 1000px;
}


.social-share-btn {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 97%;
  position: sticky;
  right: 0;
  text-align: right;
  top: 130px;
  width: 70px;
  float: right;
}
#content.site-content #main {
  overflow: initial !important;
}
body, html {
  overflow: initial !important;
}
.blog-detail-description {
  width: calc(100% - 100px);
  float: left;
}

@media (max-width:767px) {
  .social-share-btn svg, .social-share-btn .copy-area, .social-share-btn .copy-area svg {
    width: 25px;
    height: 25px;
}

.social-share-btn .copy-area {
  padding: 4px;
}
.social-share-btn  {width: 30px;}
.blog-detail-description {
  width: calc(100% - 30px);
  float: left;
}
.social-share-btn {top:160px}
}