@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.Poppins {
  font-family: Poppins, sans-serif;
}
body,
html {
  overflow-x: hidden;
}
body {
  font: 400 16px/1.5 Poppins, sans-serif;
  background-color: #fff;
  color: #212529;
  padding: 0;
  margin: 0;
}
a,
button,
div,
h1,
h2,
h3,
h4,
h5,
h6,
img,
input,
li,
p,
span,
strong,
table,
td,
textarea,
th,
tr,
ul {
  margin: 0;
  padding: 0;
  font-weight: 400;
  text-decoration: none;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
ul {
  list-style: none;
}
img {
  border: none;
  max-width: 100%;
  outline: 0;
  vertical-align: middle;
}
h1 {
  font-size: 36px;
  margin-top: 20px;
}
h2 {
  font-size: 45px;
  margin-top: 15px;
}
h3 {
  font-size: 22px;
  margin-top: 15px;
}
h4 {
  font-size: 20px;
  margin-top: 15px;
}
p {
  font-size: 16px;
  margin-top: 15px;
  font-weight: 300;
  line-height: 22px;
}
.btn {
  transition: 0.15s ease-in-out;
  -moz-user-select: none;
  font-size: 16px;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  user-select: none;
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
}
.ds-btn:hover {
  background: #2c3e7e;
  color: #fff;
}
.head-part {
  text-align: center;
  color: #2c2c51;
}
.head-part h2 {
  font-weight: 700;
  font-size: 35px;
  margin: 0;
}
*,
::after,
::before {
  box-sizing: border-box;
}
.padding {
  padding: 80px 0;
}
.wrapper {
  max-width: 1320px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.wrapper::after {
  display: table;
  clear: both;
  content: "";
}
.drupal-row {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  align-items: center;
}
.drupal-col-3 {
  flex: 0 0 auto;
  width: 25%;
  padding-left: 15px;
  padding-right: 15px;
}
.drupal-col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
  padding-left: 15px;
  padding-right: 15px;
}
.drupal-col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
  padding-left: 15px;
  padding-right: 15px;
}
.drupal-col-6 {
  flex: 0 0 auto;
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}
.drupal-col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
  padding-left: 15px;
  padding-right: 15px;
}
.drupal-col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
  padding-left: 15px;
  padding-right: 15px;
}
.drupal-col-12 {
  flex: 0 0 auto;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.drupal-col-large-box {
  flex: 0 0 auto;
  width: 41.66666667%;
  padding-left: 15px;
  padding-right: 15px;
}
.drupal-col-middle-box {
  flex: 0 0 auto;
  width: 33.33333333%;
  padding-left: 15px;
  padding-right: 15px;
}
.drupal-col-small-box {
  flex: 0 0 auto;
  width: 25%;
  padding-left: 15px;
  padding-right: 15px;
}
.comanform .row-control {
  display: flex;
  justify-content: space-between;
}
.comanform .row-control .control {
  width: 48%;
}
.comanform .input-text {
  background: 0 0;
  border: solid 1px #fff;
  color: #fff;
  height: 46px;
  font-size: 11px;
  width: 100%;
  margin: 0;
  padding: 0 15px;
}
.comanform .action .btn {
  background: #fff;
  height: auto;
  width: auto;
  color: #000;
  min-width: 215px;
  border-radius: 30px;
  border: none;
  line-height: 46px;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  margin: 0 auto;
  padding: 0;
}
.comanform .action .btn:hover {
  background: #f5821f;
  color: #fff;
}
.menu-toggle {
  border: none;
  background: #000;
  border-radius: 3px;
  padding: 5px 15px;
}
.menu-toggle .fa {
  font-size: 30px;
  color: #fff;
}
.mobile-menu .mega-menu-item {
  display: flex;
  align-items: center;
  position: relative;
}
.mobile-menu .mega-menu-link {
  display: block;
  padding: 10px 30px;
  flex-grow: 1;
}
.mega-menu.mobile-menu {
  position: absolute;
  width: 90%;
  z-index: 99;
  background: #fff;
  right: 0;
  left: 0;
  padding: 25px 0;
  margin: 0 auto;
}
.header {
  position: relative;
}
.top-bar {
  background-color: #e5f5fc;
  padding: 7px 0;
  text-align: right;
  border-bottom: solid 1px #c6efff;
}
.phoneno li {
  display: inline-block;
  padding: 0 10px;
  border-right: 1px solid #cdcdcd;
  font-size: 14px;
  font-weight: 400;
}
.phoneno li:last-child {
  border-right: none;
  padding-right: 0;
}
.phoneno li span {
  color: #ff7500;
  font-weight: 600;
}
.phoneno li a {
  color: #2b3e7f;
  font-weight: 600;
}
.main-header .head-mid {
  box-shadow: 0 0 7px #ddd;
}
.header-wrpper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 4s;
}
.head-mid {
  box-shadow: 0 0 7px #ddd;
  background: #fff;
  padding: 28px 0;
}
.header-shrink .head-mid {
  padding: 10px 0;
}
.header-shrink {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
.menu-toggle {
  display: none;
}
.menu-toggle .fa-times {
  display: none;
}
.primary-navigation {
  margin-left: auto;
}
.mega-menu-toggle {
  position: relative;
}
.mega-sub-menu {
  background: #fff;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  border: 1px solid rgba(0, 0, 0, 0.15);
  position: absolute;
  width: 340px;
  z-index: 99;
  left: auto;
  right: 0;
  display: none;
  transition: all 0.5s ease;
}
.mega-menu-toggle:hover .mega-sub-menu {
  display: block;
}
.mega-sub-menu .mega-menu-link {
  font-size: 14px;
  font-weight: 400;
  color: #032d60;
  padding: 7px 10px;
  display: block;
}
.mega-sub-menu .mega-menu-link:hover {
  color: #666 !important;
  background: #f1f1f1;
}
.mega-sub-menu .mega-menu-item {
  display: block;
}
.mega-menu-item {
  display: inline-block;
}
.mega-menu-link {
  font-size: 16px;
  font-weight: 600;
  color: #032d60;
  padding: 0 10px 0 10px;
}
.mega-menu-link:focus,
.mega-menu-link:visited {
  outline: 0;
}
.mega-menu-link:hover {
  color: #f5821f;
}
.mega-indicator {
  margin: 0 15px 0 5px;
  cursor: pointer;
  position: relative;
}
.mega-indicator-mobile {
  cursor: pointer;
  position: relative;
  float: right;
}
.mega-menu-toggle .mega-menu-link:hover {
  color: #f5821f;
}
.mega-menu-btn {
  background: #f5821f;
  color: #fff;
  padding: 10px 15px;
  border-radius: 6px;
  line-height: 28px;
  text-align: center;
}
.mega-menu-btn:hover {
  background: #2c3e7e;
  color: #fff;
}
#content.site-content #main {
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}
.banner-bg {
  /* background: url(../images/bg-banner.webp) no-repeat 0 0; */
  width: 100%;
  display: inline-block;
  background-size: cover;
  margin-bottom: 0;
}

section.banner-bg {
  position: relative;
}

section.banner-bg img.forground {
  position: absolute;
  width: 100%;
  z-index: -1;
  height: 100%;
}
.partner-logos {
  margin-top: 60px;
}
.content-area a {
  color: #009cde;
}
.partner-logos a {
  display: inline-block;
  vertical-align: middle;
  margin-top: 0 !important;
}
.banner-bg .drupal-as {
  max-width: 140px;
  margin-top: 60px;
}
.partner-logos a img {
  max-width: 200px !important;
  margin-top: 0 !important;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 0.4s;
  transition-delay: 0s;
}
.partner-logos a + a {
  margin-left: 40px;
  padding-left: 30px;
  border-left: 1px solid #fff;
}
.banner-bg h1 {
  color: #fff;
}
.content-area p {
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
}
.banner-bg p {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  padding: 0 0 14px;
}
.banner-bg .form-block {
  background: #07143d;
  width: 100%;
  max-width: 496px;
  padding: 40px;
  position: relative;
  margin-top: 35px;
  margin-bottom: 35px;
  margin-left: auto;
}
.banner-bg .form-block h2 {
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  margin-top: 0;
}
.banner-bg .form-block p {
  font-size: 14px;
  margin-top: 0;
}
.wpcf7 .screen-reader-response {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  word-wrap: normal !important;
}
.banner-bg .form-block form.wpcf7-form {
  margin-top: 10px;
}
.wpcf7-form .control {
  margin-bottom: 17px;
}
.wpcf7-form-control-wrap {
  position: relative;
}
.wpcf7-form .control textarea {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  height: 80px;
  width: 100%;
  max-width: 100%;
  border: solid 1px #fff;
  background: 0 0;
  font-size: 11px;
  resize: none;
  margin: 0;
  vertical-align: bottom;
  color: #fff;
}
.wpcf7-form .control input.wpcf7-submit {
  cursor: pointer;
  margin: 0 auto;
  padding: 0;
  min-width: 215px;
  color: #000 !important;
  width: auto;
  height: auto;
  background: #fff !important;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  border: none;
  line-height: 46px;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
}
.wpcf7-spinner {
  visibility: hidden;
  display: inline-block;
  background-color: #23282d;
  opacity: 0.75;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 100%;
  padding: 0;
  margin: 0 24px;
  position: relative;
}
.wpcf7-spinner:before {
  content: "";
  position: absolute;
  background-color: #fbfbfc;
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  border: none;
  border-radius: 100%;
  transform-origin: 8px 8px;
  animation-name: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.wpcf7-form .control input.wpcf7-submit:hover {
  background: #f5821f !important;
  color: #fff !important;
}
::-webkit-input-placeholder {
  color: #fff;
}
::-moz-placeholder {
  color: #fff;
}
:-ms-input-placeholder {
  color: #fff;
}
:-moz-placeholder {
  color: #fff;
}
.drupal-business {
  margin: 63px 0;
}
.drupal-business h2 {
  color: #00224f;
  font-size: 38px;
  font-weight: 300;
}
.drupal-business h3 {
  color: #00224f;
  margin: 30px 0 23px;
}
.drupal-business p {
  margin-bottom: 17px;
  color: #00224f;
  font-size: 14px;
}
.content-area a.ds-btn {
  color: #fff;
}
.ds-btn {
  line-height: 33px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  color: #fff;
  margin-top: 15px;
  padding: 7px 51px;
  font-size: 17px;
  display: inline-block;
  background: #f88f35;
  background: linear-gradient(
    90deg,
    rgba(248, 143, 53, 1) 0,
    rgba(245, 130, 31, 1) 100%
  );
  color: #fff;
}
.why-ds-bg {
  background: url(../images/why-ds-bg.jpg) no-repeat 0 0;
  width: 100%;
  display: inline-block;
  background-size: cover;
  margin: 0;
}
.why-ds-bg h2 {
  color: #fff;
  margin-bottom: 10px;
  font-size: 45px;
  font-weight: 600;
}
.why-ds-bg p {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
}
.why-ds-bg .why-btn {
  text-align: right;
  margin-top: 80px;
}
.why-ds-bg .ds-symbol {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  margin-top: 45px;
  border-right: solid 1px #286bcf;
  position: relative;
  -webkit-flex-wrap: wrap;
}
.why-ds-bg .ds-symbol .block:before,
.why-ds-bg .ds-symbol:before {
  height: 79px;
  width: 1px;
  content: "";
  background: #98bad6;
  display: inline-block;
  position: absolute;
  top: 30%;
  right: 0;
  z-index: 1;
}
.why-ds-bg .ds-symbol .block {
  width: 20%;
  position: relative;
  border-left: solid 1px #286bcf;
  padding-left: 39px;
}
.why-ds-bg .ds-symbol .block img {
  max-width: 55px;
}
.why-ds-bg h3 {
  color: #fff;
}
.why-ds-bg .ds-symbol .block h3 {
  font-weight: 600;
  font-size: 37px;
}
.why-ds-bg .ds-symbol .block p {
  max-width: 172px;
  line-height: 21px;
}
.service-block {
  background: url(../images/service-bg.webp) no-repeat center 48%;
  text-align: center;
  margin: 0;
}
.service-block .block {
  height: calc(100% - 90px);
  min-height: 307px;
  margin: 90px 14px 0;
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0 0 33px #b3cde2;
  -moz-box-shadow: 0 0 33px #b3cde2;
  box-shadow: 0 0 33px #b3cde2;
  padding: 30px;
  -webkit-border-radius: 30px 0;
  -moz-border-radius: 30px 0;
  border-radius: 30px 0;
  padding-top: 73px;
}
.service-block .block .icons {
  border: solid 3px #0084be;
  display: inline-block;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  position: absolute;
  top: -50px;
  background: #fff;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  cursor: pointer;
}
.service-block .block .icons:hover {
  background: #0084be;
}
.service-block .block .icons:hover img {
  filter: brightness(0) invert(1);
}
.service-block .block h4 {
  font-size: 17px;
  font-weight: 600;
  color: #0f2028;
}
.service-block .block p {
  color: #66778d;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
}
.why-build {
  text-align: center;
  margin: 0;
}
.why-build img {
  margin-top: 40px;
}
.why-build .head-part p {
  color: #00224f;
  width: 80%;
  margin: 12px auto 10px;
  font-size: 15px;
}
.casestudy-block-sec {
  margin-bottom: 2rem;
}
.casestudy-block {
  padding-bottom: 30px;
  padding-top: 30px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 1370px;
  width: 100%;
  margin: 0 auto;
}
.casestudy-block .casestudy .study-slider {
  width: 60%;
  background: #fff;
  padding: 40px 52px;
  -webkit-box-shadow: 0 4px 35px 4px #b6dcec;
  -moz-box-shadow: 0 4px 35px 4px #b6dcec;
  box-shadow: 0 4px 35px 4px #b6dcec;
  float: right;
  margin-top: 8%;
  margin-left: -12%;
}
.casestudy-block .casestudy .study-slider .slide-text h2 {
  font-size: 34px;
  font-weight: 600;
  color: #2c2c51;
}
.casestudy-block .casestudy .study-slider .slide-text .btn {
  float: left;
  margin: 18px 0;
  line-height: 50px;
  border: none;
  min-width: 180px;
  padding: 0;
  background: #f88f35;
  background: linear-gradient(
    90deg,
    rgba(248, 143, 53, 1) 0,
    rgba(245, 130, 31, 1) 100%
  );
}
.casestudy-block .casestudy .study-slider .slide-text .btn:hover {
  background: #2c3e7e;
  color: #fff;
  border: none;
}
.casestudy-block .casestudy {
  margin: 40px 0 0;
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
}
.casestudy-block .casestudy:before {
  content: "";
  background: url(../images/download.png);
  position: absolute;
  right: 6%;
  width: 166px;
  height: 136px;
}
.casestudy-block .casestudy .img {
  max-width: 100%;
  width: 52%;
  float: left;
}
.casestudy-block .casestudy .img img {
  width: 100%;
}
.casestudy-block .casestudy .study-slider .owl-nav {
  display: none;
}
.casestudy-block .casestudy .study-slider .owl-dots {
  text-align: center;
}
.casestudy-block .casestudy .study-slider .owl-dots button {
  background: 0 0;
}
.casestudy-block .casestudy .study-slider .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 5px 3px;
  background: #eff2f7;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -ms-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.casestudy-block .casestudy .study-slider .owl-dots .owl-dot.active span {
  background: #b6dcec;
}
.owl-theme .owl-dots .owl-dot span {
  background: #fff;
  display: block;
  width: 10px;
  height: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin: 0 4px;
}
.owl-theme .owl-dots .owl-dot.active span {
  background: #fff;
  opacity: 0.5;
}
.drupal-int {
  margin: 0 auto;
  width: 100%;
  max-width: 1270px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.drupal-int .head-part {
  margin-bottom: 3.8rem;
}
.drupal-int .int-feature {
  position: relative;
}
.drupal-int .int-feature .int-img {
  max-width: 421px;
  margin: 0 auto;
}
.drupal-int .int-feature .f-icon {
  position: absolute;
  top: 0;
}
.drupal-int .int-feature .f-icon.one {
  left: 6%;
}
.drupal-int .int-feature .f-icon .icons {
  float: left;
  width: 120px;
  height: 120px;
  background: #0084be;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  line-height: 120px;
  margin-right: 16px;
}
.drupal-int .int-feature .f-icon:hover span.icons img {
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}
.drupal-int .int-feature .f-icon.f-icon.five .icons,
.drupal-int .int-feature .f-icon.f-icon.six .icons,
.drupal-int .int-feature .f-icon.four .icons {
  float: right;
  margin-left: 16px;
}
.drupal-int .int-feature .f-icon .icons img {
  filter: brightness(0) invert(1);
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
}
.drupal-int .int-feature .f-icon h4 {
  width: auto;
  font-size: 17px;
  color: #000;
  font-weight: 400;
  display: inline-block;
  margin-top: 47px;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  transition: 0.2s;
}
.drupal-int .int-feature .f-icon.two {
  top: 43%;
}
.drupal-int .int-feature .f-icon.three {
  top: 87%;
  left: 6%;
}
.drupal-int .int-feature .f-icon.four {
  right: 6%;
}
.drupal-int .int-feature .f-icon.five {
  top: 43%;
  right: 0;
}
.drupal-int .int-feature .f-icon.six {
  right: 6%;
  top: 87%;
}
.testimonial-bg {
  background: url(../images/testimonial-bg.webp) no-repeat 0 0;
  width: 100%;
  display: inline-block;
  background-size: cover;
  margin-top: 8rem;
  padding: 26px 0;
  margin-bottom: 0;
}
.testimonial-bg .head-part {
  margin-bottom: 1rem;
}
.testimonial-bg .head-part p {
  font-size: 15px;
  letter-spacing: 2px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}
.testimonial-bg .head-part h2 {
  margin-top: 5px;
  font-weight: 300;
  font-size: 25px;
  color: #fff;
}
.testimonial-bg .slide-text {
  width: 100%;
  max-width: 777px;
  margin: 0 auto;
  position: relative;
}
.testimonial-bg .slide-text .quation {
  position: relative;
}
.testimonial-bg .slide-text p {
  font-weight: 300;
  color: #fff;
  text-align: center;
  width: calc(100% - 62px);
  margin: 0 auto;
}
.testimonial-bg .slide-text .quation:before {
  content: "";
  background: url(../images/quation.png);
  position: absolute;
  top: 0;
  left: -30px;
  width: 31px;
  height: 21px;
  background-size: contain;
  background-repeat: no-repeat;
}
.testimonial-bg .slide-text .quation:after {
  content: "";
  background: url(../images/quation-down.png);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 31px;
  height: 21px;
  background-size: contain;
  background-repeat: no-repeat;
}
.testimonial-bg .slide-text .avatar {
  margin-top: 2rem;
}
.testimonial-bg .slide-text .avatar h4 {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.testimonial-bg .owl-carousel .owl-nav {
  display: none;
}
.testimonial-bg .owl-carousel .owl-dots {
  margin-top: 25px;
}
.brand-block {
  margin-bottom: 3rem !important;
  margin-top: 3rem;
}
.brand-block .head-part {
  margin-bottom: 2.5rem;
}
.brand-block .head-part h2 {
  font-weight: 600;
}
.brand-block .brand-logo ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.brand-block .brand-logo ul li img {
  filter: grayscale(100%);
  cursor: pointer;
}
.brand-block .brand-logo ul li:hover img {
  filter: none;
}
.footer-main {
  background: #030323;
}
.footer-main .footer-top {
  padding-top: 70px;
  padding-bottom: 55px;
}
.footer-top h6 {
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  margin-bottom: 1.4rem;
}
.footer-top h6:before {
  content: "";
  width: 3px;
  height: 15px;
  background: #f58220;
  display: block;
  float: left;
  margin-right: 15px;
  margin-top: 3px;
}
.footer-top .sitelinks ul li a {
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  position: relative;
  display: inline-block;
  padding: 5px 18px;
  transition: 0.2s;
  left: 0;
}
.footer-top .sitelinks ul li a:hover {
  color: #f58220;
}
.footer-top .sitelinks ul li a:before {
  content: "\f101";
  font-family: FontAwesome;
  position: absolute;
  left: 0;
  color: #f58220;
}
.footer-top .social-links li:not(:last-child) {
  margin-bottom: 5px;
}
.footer-top .social-links li a {
  display: inline-block;
  padding: 4px 0;
  color: #fff;
  line-height: 33px;
}
.footer-top .social-links li a span.icon {
  display: block;
  float: left;
  border: 2px solid #f58220;
  margin-right: 11px;
  transition: 0.2s;
  border-radius: 100%;
  width: 33px;
  height: 33px;
  text-align: center;
  line-height: 32px;
  color: #f58220;
}
.footer-top .social-links li a:hover {
  color: #f58220;
}
.footer-top .social-links li a:hover span.icon .fa {
  transform: rotate(360deg);
  transition: 0.7s;
  -webkit-transition: 0.7s;
}
.footer-top .address-sec li {
  color: #fff;
  font-weight: 300;
  display: block;
  padding: 4px 0;
}
.footer-top .address-sec li span {
  color: #f58220;
}
.footer-top .address-sec li a {
  color: #fff;
  font-weight: 300;
}
.footer-top .address-sec li a:hover {
  color: #f58220;
}
.footer-main .foter-bottom {
  background: #01011a;
  padding: 21px 0;
}
.footer-main .foter-bottom .copyright {
  display: flex;
  justify-content: space-between;
}
.footer-main .foter-bottom .copyright p {
  margin: 0;
  color: #9494a2;
  font-size: 14px;
  font-weight: 400;
}
.footer-main .foter-bottom .copyright ul.links {
  display: inline-block;
  width: auto;
}
.footer-main .foter-bottom .copyright ul.links li {
  float: left;
  padding-left: 18px;
  line-height: 1;
  margin-left: 15px;
  border-left: solid 1px #9494a2;
}
.footer-main .foter-bottom .copyright ul.links li:first-child {
  border-left: none;
}
.footer-main .foter-bottom .copyright ul.links li a {
  color: #9494a2;
  font-size: 14px;
  font-weight: 400;
}
.footer-main .foter-bottom .copyright ul.links li a:hover {
  color: #fff;
}
.content-area .header-title {
  background: url(../images/strip-bg.jpg);
  background-size: cover;
  background-position: center;
  padding: 65px 20px;
  border-top: solid 3px #33a7da;
  border-bottom: solid 3px #33a7da;
  text-align: center;
  font-weight: 700;
  color: #fff;
}
.content-area .header-title h1 {
  font-size: 38px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  margin: 0;
}
.drupal-keyfeature {
  background: #f6f7fa;
  padding: 28px 0 41px;
  border-top: solid 1px #d8d8ef;
}
.drupal-keyfeature h2 {
  padding-bottom: 20px;
  color: #00224f;
}
.list-block {
  margin-top: 9px;
  display: block;
  width: 100%;
}
.list-block li {
  font-size: 16px;
  font-weight: 400;
  margin: 12px 0;
  position: relative;
  padding: 0 0 0 33px;
}
.list-block li:before {
  content: "\f105";
  font-family: fontawesome;
  width: 25px;
  height: 25px;
  line-height: 25px;
  display: inline-block;
  background: #f5821f;
  color: #fff;
  text-align: center;
  margin: 0;
  position: absolute;
  left: 0;
}
.list-block li:hover:before {
  background: #0078ad;
}
.new-heading {
  text-align: center;
  margin: 2em 0 1em;
  font-weight: 300;
}
.service-grid .row-block {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 0 9px #dce5e8;
  margin-bottom: 23px;
}
.service-grid .row-block:nth-child(odd) {
  flex-direction: row-reverse;
  border-radius: 0 0 0 80px;
}
.service-grid .row-block .block {
  width: 50%;
}
.service-grid .row-block .block .text {
  padding: 8px 2rem 30px;
}
.service-grid .row-block .block h2 {
  font-size: 27px;
}
.inner-head {
  padding: 3em 0;
}
.inner-head h2 {
  font-weight: 300;
  font-size: 45px;
}
.inner-head p {
  font-size: 1.2rem;
  line-height: normal;
  max-width: 1000px;
  margin: auto;
  margin-top: 10px;
  padding: 0 12px;
}
.service-grid .row-block:nth-child(even) {
  border-radius: 0 80px 0 0;
}
.whychose-ds {
  background: #f6f7fa;
  padding: 30px 0;
}
.whychose-ds .why-ds-img {
  text-align: center;
}
.whychose-ds .content {
  background: #fff;
  min-height: 249px;
  padding: 30px;
  box-shadow: 0 0 23px #eceff9;
}
.whychose-ds .content h2 {
  margin: 0;
}
.drupal-theme-bg {
  background: #00599d;
  padding: 0 0 50px;
  border-top: 1px solid #dde3e8;
  border-bottom: 1px solid #dde3e8;
  color: #fff;
}
.drupal-theme-bg .inner-head {
  color: #fff;
}
.drupal-theme-bg p b {
  font-weight: 700;
  color: #f5821f;
}
.full-headline {
  text-align: center;
  margin: 100px 0;
}
.full-headline .content-with-line {
  border-left: 5px solid #f5821f;
  margin-left: 100px;
  padding-left: 15px;
  display: inline-block;
  vertical-align: bottom;
  text-align: left;
}
.full-headline h2 {
  font-size: 25px;
  font-weight: 400;
  line-height: 30px;
  color: #00224f;
  margin: 0;
}
.case-study-list .case-study-box {
  margin-bottom: 30px;
}
.case-study-list .case-study-box .box-inner {
  box-shadow: 0 0 15px 10px rgb(0 0 0 / 10%);
  border-radius: 7px;
  overflow: hidden;
  height: 100%;
}
.case-study-list .case-study-box .case-study-img {
  position: relative;
  height: 0;
  padding-bottom: 60%;
  border-bottom: 5px solid #f88f35;
}
.case-study-list .case-study-box .case-study-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.case-study-list .case-study-box .case-study-img .ds-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: 0.4s;
  opacity: 0;
  z-index: 2;
  width: 265px;
  text-align: center;
  padding: 6px 0;
  display: inline-block;
}
.case-study-list .case-study-box .case-study-img:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  transform: scale(0);
  transition: 0.2s;
  content: "";
}
.case-study-list .case-study-box .case-study-detail {
  padding: 25px;
}
.case-study-list .case-study-box .case-study-detail h2 {
  font-size: 24px;
  margin-top: 0;
}
.case-study-list .case-study-box .case-study-detail h2 a {
  color: #212529;
}
.case-study-list .case-study-box .box-inner:hover .case-study-img .ds-btn {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}
.case-study-list .case-study-box .box-inner:hover .case-study-img:after {
  transform: scale(1);
}
.form-section {
  background: url(../images/form-bg.jpg) no-repeat 0 0;
  display: inline-block;
  width: 100%;
  background-size: cover;
  background-position: center;
  margin-top: 3rem;
}
.form-section .form-block {
  background-color: #07143d;
  background-size: cover;
  width: 100%;
  max-width: 539px;
  padding: 36px 40px 90px;
  position: relative;
  margin-top: -44px;
}
.form-section .form-block h2 {
  margin-top: 0;
  color: #fff;
  font-size: 23px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 9px;
}
.form-section .form-block p {
  font-size: 13px;
  color: #fff;
  text-align: center;
  line-height: 20px;
  width: 87%;
  margin: 0 auto;
}
.form-section .form-block form {
  margin-top: 26px;
}
.form-section .form-block .control {
  margin-bottom: 17px;
}
.wpcf7-form-control-wrap {
  position: relative;
}
.wpcf7-form-control-wrap input {
  background: 0 0;
  height: 46px;
  border: 1px solid #fff;
  font-size: 11px;
  width: 100%;
  padding: 0 15px;
  border-radius: 0;
  color: #fff;
}
.with-img-section {
  margin: 0;
  position: relative;
  padding: 80px 0;
  z-index: 1;
}
.with-img-section:not(.bg-sec):before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  width: 70%;
  box-shadow: 0 0 40px 0 rgb(0 0 0 / 15%);
  background: #fff;
}
.with-img-section .content-part-left {
  padding-right: 60px;
}
.with-img-section h2 {
  font-size: 50px;
  font-weight: 300;
  color: #00224f;
  margin-top: 0;
}
.with-img-section h3 {
  font-size: 20px;
  font-weight: 600;
  color: #474747;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.with-img-section .content-part-left p {
  font-size: 20px;
  color: #474747;
  line-height: 38px;
  margin-top: 0;
}
.with-img-section .content-part-left p strong {
  font-weight: 600;
  color: #f5821f;
  margin: 0 10px 0 0;
}
.with-img-section .img-part {
  width: 50vw;
}
.with-img-section .img-part img {
  width: 100%;
}
.with-img-section.bg-sec {
  background: #030423;
  color: #fff;
  margin-top: -80px;
  z-index: 0;
  padding-top: 160px;
}
.drupal-align-items-center {
  align-items: center;
}
.with-img-section .img-part.float-right {
  float: right;
}
.with-img-section .content-part-right {
  padding-left: 60px;
}
.with-img-section h4 {
  font-size: 32px;
  font-weight: 500;
  margin-top: 0;
}
.with-img-section h4 span {
  color: #f5821f;
}
.with-img-section .content-part-right p {
  margin-top: 25px;
}
.caststudy-slider {
  position: relative;
  background: #030423;
  margin: 0;
  z-index: 1;
  padding: 150px 0 135px;
}
.caststudy-slider:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 50%;
  background: #f6821f;
  border-radius: 0 0 450px;
  z-index: -1;
}
.screen-slider {
  display: block;
  width: 100%;
  max-width: 882px;
  margin: 0 auto;
  position: relative;
}
.screen-slider:after,
.screen-slider:before {
  content: "";
  width: 152px;
  height: 133px;
  background: #030423;
  border-radius: 15px;
  position: absolute;
  top: -25px;
}
.screen-slider:before {
  left: -25px;
}
.screen-slider:after {
  right: -25px;
}
.screen-slider .screen-img {
  position: relative;
  z-index: 1;
}
.caststudy-slider .studies-slider {
  position: absolute;
  top: 33px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 2;
  padding: 0 31.5px;
}
.studies-slider .slide {
  position: relative;
  width: 100%;
  height: 0;
  display: inline-block;
  vertical-align: bottom;
  overflow: hidden;
  padding-bottom: 56.2%;
}
.studies-slider .slide img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.studies-slider .owl-nav {
  display: none;
}
.studies-slider .owl-dots {
  text-align: center;
  margin-top: 260px !important;
}
.studies-slider .owl-dots .owl-dot {
  background: 0 0 !important;
}
.studies-slider .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 5px 3px;
  background: #606277;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}
.studies-slider .owl-dots .owl-dot.active span {
  background: #f6821f !important;
  opacity: 1;
}
.why-ds {
  margin: 0;
  padding: 60px 0 20px;
}
.why-ds h2 {
  font-size: 40px;
  font-weight: 400;
  color: #00224f;
  border-left: 10px solid #f5821f;
  padding-left: 40px;
  line-height: 1.2;
}
.why-ds h2 span {
  color: #f5821f;
}
.drupal-keyfeature h2 {
  padding-bottom: 20px;
  color: #00224f;
}
.drupal-keyfeature .drupal-col-6 {
  padding: 0 15px 0 0;
}
.whychose-ds .row {
  align-items: center;
}
.whychose-ds .drupal-row {
  align-items: center;
}
#contact-form {
  background: url(../images/contact-form-bg.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  padding: 66px 0;
}
#contact-form .wpcf7-form {
  padding-left: 200px;
}
#contact-form .control textarea {
  min-height: 180px;
}
#contact-form h3 {
  font-size: 45px;
  color: #fff;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 50px;
  margin-top: 2px;
}
.address-sec p {
  margin-bottom: 26px;
  color: #fff;
}
.detail-contact li {
  color: #fff;
  font-size: 14px;
  margin-bottom: 15px;
  font-weight: 300;
  position: relative;
  padding-left: 20px;
}
.detail-contact li i {
  position: absolute;
  top: 3px;
  left: 0;
  font-size: 16px;
}
.detail-contact li a {
  color: #fff;
}

.responsive-map iframe {
  width: 100%;
  height: 450px;
}
.content-area ol li {
  font-size: 14px;
  font-weight: 300;
  margin: 0 0 20px;
  padding: 0;
  color: #5d5d5d;
  line-height: 22px;
}
.content-area .bluetxt {
  color: #f58220;
  margin-bottom: 6px;
  display: block;
}
.privacy-page ol {
  margin-top: 20px;
  padding: 0;
}
.content-area .mar-t-b {
  margin: 60px 0;
}

/*Thanks page css here*/
.thanks .head-part {
  text-align: left;
}
.thanks h4 {
  text-align: center;
}
.drupal-thanks-sec {
  padding: 50px 0;
}

.drupal-thanks-sec .drupal-row {
  align-items: start;
}

.drupal-thanks-sec h2 {
  margin: 0;
}
.drupal-thanks-sec p {
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
}
.drupal-contact {
  font-weight: 600 !important;
}

.drupal-about-more {
  background: #00599d;
  padding: 70px 0;
  color: #fff;
  margin: 70px 0;
}
.drupal-about-more h2 {
  padding-bottom: 20px;
}
.drupal-about-more li {
  padding-bottom: 10px;
  position: relative;
  padding-left: 20px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-size: 14px;
}
.drupal-about-more li:hover {
  transform: translate(10px);
}
.drupal-about-more li::before {
  content: "\f101";
  position: absolute;
  left: 0;
  font-family: "FontAwesome";
}

.ds_call {
  background: #fff;
  border-radius: 30px 0;
  box-shadow: 0 0 33px #b3cde2;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}
.ds_call .ds_head_contant {
  text-align: center;
  padding: 40px 0;
}
.ds_call .ds_head_contant h3 {
  margin: 0;
  font-size: 22px;
}
.ds_call .ds_head_contant h3 a {
  font-weight: 600;
  color: #000;
}
.ds_call a.catalog_ds {
  padding: 0 10px;
  display: inline-block;
  position: relative;
  bottom: -20px;
  transition: all 0.2s ease-in-out;
  width: 100%;
}
.ds_call a.catalog_ds .catalog_ds_inner {
  position: relative;
}
.ds_call a.catalog_ds .catalog_ds_inner picture.catalog_ds_img {
  display: inline-block;
}
.ds_call a.catalog_ds .catalog_ds_img img {
  width: 100%;
  border-radius: 6px 6px 0 0;
  overflow: visible;
  display: inline-block;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.ds_call a.catalog_ds .catalog_ds_img img:hover {
  transform: translateY(-10px);
}
.ds_call a.catalog_ds .catalog_ds_inner .catalog_ds_logo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 50px;
  display: inline-block;
  width: 75px;
  height: 75px;
  line-height: 55px;
  border: 2px solid #e8e8e8;
}
.ds_call a.catalog_ds .catalog_ds_inner .catalog_ds_logo img {
  vertical-align: middle;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: auto;
}

.drupal-company-website h2 {
  padding-bottom: 40px;
}
.drupal-info-wrap {
  background: #fff;
  box-shadow: 0 0 33px #b3cde2;
  border-radius: 10px;
  padding: 25px;
}

.drupl-logo-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}
.drupl-logo-wrap img {
  width: 150px;
}
.content-wrap {
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  flex-wrap: wrap;
}

.content-wrap li {
  display: flex;
  flex: 0 0 50%;
  align-items: center;
  margin-bottom: 35px;
}
.icon-wrap {
  background: #0d599d;
  border-radius: 50px;
  margin-right: 15px;
  padding: 15px;
  min-height: 70px;
  width: 70px;
  min-width: 70px;
  height: 70px;
}

.text-wrap {
  font-size: 16px;
  font-weight: 600;
}

.thanks .head-part {
  text-align: left;
}
.drupal-thanks-sec {
  padding-bottom: 50px;
}

@media (max-width: 1199px) {
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 18px;
  }
  .wrapper {
    max-width: 960px;
  }
  .padding {
    padding: 40px 0;
  }
  .site-logo {
    max-width: 300px;
  }
  .banner-bg .form-block h2 {
    font-size: 20px;
  }
  .drupal-business h2 {
    font-size: 24px;
    font-weight: 400;
  }
  .drupal-business h3 {
    margin: 15px 0;
  }
  .why-ds-bg .drupal-col-8 {
    width: 100%;
  }
  .why-ds-bg .ds-symbol .block {
    padding: 0 15px;
  }
  .why-ds-bg .ds-symbol .block h3 {
    font-size: 24px;
  }
  .why-ds-bg h2 {
    font-size: 30px;
  }
  .head-part h2 {
    font-size: 30px;
  }
  .service-block .block {
    margin: 30px 0;
    height: calc(100% - 60px);
  }
  .service-block .head-part {
    margin-bottom: 60px;
  }
  .casestudy-block .casestudy {
    padding: 0 15px;
  }
  .drupal-int .int-feature .int-img {
    max-width: 315px;
  }
  .drupal-int .int-feature .f-icon .icons {
    height: 100px;
    width: 100px;
    line-height: 100px;
    transition: 0.3s all ease-in-out;
  }
  .drupal-int .int-feature .f-icon .icons img {
    max-width: 90px;
  }
  .brand-block .brand-logo ul li {
    flex: 0 0 auto;
    max-width: 20%;
  }
  .drupal-col-large-box,
  .drupal-col-small-box {
    width: 33.33333333%;
  }
  .content-area .header-title {
    padding: 40px;
  }
  .new-heading {
    margin: 1em 0 1em;
  }
  .inner-head {
    padding: 1em 0;
  }
  .full-headline {
    margin: 50px 0;
  }
  #contact-form .wpcf7-form {
    padding-left: 90px;
  }
  #contact-form h3 {
    font-size: 30px;
    line-height: 30px;
  }
}
@media (max-width: 991px) {
  h2 {
    font-size: 24px;
  }
  p {
    font-size: 14px;
  }
  .phoneno li {
    font-size: 12px;
    font-weight: 400;
  }
  .phoneno li a {
    font-weight: 500;
  }
  .drupal-row {
    align-items: center;
  }
  .site-logo {
    max-width: 180px;
  }
  .mega-menu-link {
    font-size: 14px;
  }
  .mega-menu-btn {
    padding: 8px 12px;
  }
  .banner-bg .form-block {
    padding: 20px;
  }
  .why-ds-bg .ds-symbol {
    justify-content: center;
    border-right: none;
    gap: 25px 15px;
  }
  .why-ds-bg .ds-symbol .block {
    width: 30%;
    padding: 0;
    text-align: center;
    border-left: none;
  }
  .why-ds-bg .ds-symbol .block p {
    max-width: inherit;
  }
  .why-ds-bg .ds-symbol .block:before,
  .why-ds-bg .ds-symbol:before {
    width: initial;
  }
  .service-block .head-part {
    margin-bottom: 40px;
  }
  .service-block .drupal-col-4 {
    width: 50%;
  }
  .casestudy-block .casestudy .study-slider .slide-text h2 {
    font-size: 24px;
    margin-top: 0;
  }
  .casestudy-block .casestudy .study-slider {
    width: 52%;
    padding: 20px 48px;
  }
  .drupal-int .int-feature .f-icon .icons {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
  .drupal-int .int-feature .f-icon .icons img {
    max-width: 60px;
  }
  .drupal-int .int-feature .int-img {
    width: 255px;
  }
  .drupal-int .int-feature .f-icon h4 {
    font-size: 15px;
    margin-top: 30px;
  }
  .testimonial-bg .slide-text .quation:before {
    left: 0;
  }
  .brand-block .brand-logo ul {
    justify-content: center;
    flex-wrap: wrap;
    gap: 0 25px;
  }
  .drupal-col-large-box,
  .drupal-col-middle-box {
    width: 30%;
  }
  .drupal-col-small-box {
    width: 40%;
  }
  .content-area .header-title {
    padding: 40px;
  }
  .content-area .header-title h1 {
    font-size: 24px;
  }
  .service-grid .row-block {
    flex-wrap: wrap;
  }
  .service-grid .row-block .block {
    width: 100%;
  }
  .service-grid .row-block .block h2 {
    font-size: 20px;
  }
  .service-grid .row-block .block .text {
    padding: 15px;
  }
  .service-grid .row-block:nth-child(odd) {
    border-radius: 0;
  }
  .content-area .header-title {
    padding: 20px;
  }
  .full-headline h2 {
    font-size: 16px;
  }
  .full-headline .content-with-line {
    margin-left: 0;
    display: block;
  }
  .with-img-section h2 {
    font-size: 30px;
  }
  .with-img-section h3 {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .with-img-section .content-part-left p {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
  }
  .with-img-section {
    padding: 50px 0;
  }
  .with-img-section h4 {
    font-size: 20px;
  }
  .with-img-section .content-part-right p {
    margin-top: 15px;
  }
  .caststudy-slider {
    padding: 60px 0 45px;
  }
  .studies-slider .owl-dots {
    margin-top: 140px;
  }
  .why-ds h2 {
    font-size: 20px;
  }
  .privacy-page ol {
    margin: 0 15px;
  }
  .content-area .mar-t-b {
    margin: 15px 15px;
  }

  #contact-form .wpcf7-form {
    padding-left: 30px;
  }
  #contact-form h3 {
    font-size: 20px;
  }
  .address-sec p {
    font-size: 12px;
  }
  .detail-contact li {
    font-size: 12px;
  }
  /**thanks page here**/
  .content-wrap li {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
  .icon-wrap {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .drupal-col-3 {
    width: 33.333%;
  }
  /**thanks page here**/
  .drupal-thanks .drupal-col-7,
  .drupal-thanks .drupal-col-5 {
    width: 100%;
  }
  .icon-wrap {
    margin-bottom: 0;
    margin-right: 15px;
  }
  .drupal-info-wrap {
    margin-bottom: 50px;
  }
  .drupal-col-3 {
    width: 50%;
  }
}
@media (max-width: 767px) {
  h2 {
    font-size: 24px;
  }
  .menu-toggle {
    display: block;
  }
  .mega-menu {
    display: none;
  }
  .mega-menu.mobile-menu {
    display: block;
  }
  .mega-menu-link {
    font-size: 16px;
    font-weight: 600;
  }
  .mega-indicator {
    padding: 0 20px;
  }
  .mega-menu-btn {
    margin: 0 25px;
  }
  .toggle-open .fa-times {
    display: block;
  }
  .toggle-open .fa-bars {
    display: none;
  }
  .mega-menu-toggle:hover .mega-sub-menu {
    display: none;
  }
  .mobile-menu .mega-sub-menu.show {
    position: absolute;
    top: 30px;
    width: 100%;
    display: block;
  }
  .mega-indicator {
    margin-right: 0;
  }
  .drupal-col-6 {
    width: 100%;
  }
  .banner-bg .form-block {
    max-width: 100%;
  }
  .drupal-business {
    margin: 20px 0;
  }
  .ds-btn {
    margin-bottom: 30px;
  }
  .service-block .drupal-col-4 {
    width: 100%;
  }
  .service-block .block {
    min-height: auto;
  }
  .casestudy-block .casestudy {
    padding: 0;
  }
  .casestudy-block .casestudy .img {
    width: 100%;
    float: initial;
  }
  .casestudy-block .casestudy .study-slider {
    width: 100%;
    float: initial;
    margin-top: 0;
    margin-left: 0;
  }
  .casestudy-block .casestudy:before {
    display: none;
  }
  .drupal-int .int-feature .f-icon {
    position: relative;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    margin-top: 15px;
    width: 50%;
    float: left;
  }
  .drupal-int .int-feature .f-icon.f-icon.five .icons,
  .drupal-int .int-feature .f-icon.f-icon.six .icons,
  .drupal-int .int-feature .f-icon.four .icons {
    float: left;
    margin-left: 0;
  }
  .testimonial-bg {
    margin-top: 2rem;
  }
  .drupal-col-small-box {
    width: 100%;
  }
  .drupal-col-large-box,
  .drupal-col-middle-box {
    width: 50%;
  }
  .brand-block {
    margin: 2rem 0 !important;
  }
  .footer-main .footer-top {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .footer-main .foter-bottom .copyright {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px 0;
  }
  .content-area .header-title {
    padding: 40px;
  }
  .drupal-col-5,
  .drupal-col-7 {
    width: 100%;
    padding: 0;
  }
  .why-ds-img img {
    max-width: 55%;
    margin-bottom: 30px;
  }
  .whychose-ds .content {
    padding: 15px;
  }
  .form-section .form-block {
    padding: 15px;
  }
  .with-img-section:not(.bg-sec):before {
    width: 100%;
    box-shadow: none;
  }
  .with-img-section h3 {
    margin: 10px 0;
  }
  .with-img-section .img-part {
    width: initial;
    margin-bottom: 15px;
  }
  .with-img-section {
    padding: 10px 0;
  }
  .with-img-section.bg-sec {
    padding-top: 120px;
  }
  .with-img-section .content-part-right {
    padding: 0 15px;
  }
  .why-ds {
    padding: 30px 0;
  }
  #contact-form .drupal-col-4,
  #contact-form .drupal-col-8 {
    width: 100%;
  }
  #contact-form .wpcf7-form {
    padding-left: 0;
    padding-top: 15px;
  }
  #contact-form {
    padding: 30px 0;
  }

  /**thanks page css**/
  .icon-wrap {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .drupal-info-wrap {
    margin: 30px 0;
  }
  .text-wrap {
    width: 100%;
  }
}
@media (max-width: 575px) {
  h2 {
    font-size: 20px;
  }
  .wrapper {
    max-width: 100%;
  }
  .padding {
    padding: 20px 0;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 16px;
  }
  p {
    margin-top: 10px;
  }
  .phoneno li {
    font-size: 11px;
    font-weight: 700;
  }
  .phoneno li:nth-child(2) {
    border-right: none;
  }
  .site-logo {
    max-width: 150px;
  }
  .head-mid {
    padding: 15px 0;
  }
  .partner-logos a img {
    max-width: 120px !important;
  }
  .partner-logos a + a {
    padding-left: 0;
    margin-left: 14px;
  }
  .banner-bg .form-block {
    padding: 10px;
  }
  .banner-bg .drupal-col-6 {
    padding: 0;
  }
  .banner-bg .form-block h2 {
    font-size: 16px;
    padding-bottom: 15px;
  }
  .comanform .row-control {
    flex-wrap: wrap;
  }
  .comanform .row-control .control {
    width: 100%;
  }
  .drupal-business h2 {
    font-size: 20px;
  }
  .why-ds-bg h2 {
    font-size: 20px;
    margin-bottom: 0;
  }
  .why-ds-bg .ds-symbol {
    gap: 10px;
  }
  .why-ds-bg .ds-symbol .block {
    width: 48%;
  }
  .head-part h2 {
    font-size: 20px;
  }
  .service-block .block {
    padding: 15px;
    padding-top: 30px;
  }
  .service-block .block .icons img {
    max-width: 80px;
  }
  .why-build .head-part p {
    width: 100%;
  }
  .casestudy-block .casestudy .study-slider .slide-text h2 {
    font-size: 20px;
  }
  .casestudy-block-sec {
    margin-bottom: 0;
  }
  .drupal-int {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .drupal-int .int-feature .f-icon {
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .brand-block .brand-logo ul li {
    max-width: 40%;
  }
  .drupal-col-large-box,
  .drupal-col-middle-box,
  .drupal-col-small-box {
    width: 100%;
  }
  .footer-top h6 {
    margin-top: 1.4rem;
  }
  .footer-main .foter-bottom .copyright ul.links li {
    float: initial;
    display: inline;
  }
  .footer-main .foter-bottom .copyright ul.links li a {
    font-size: 12px;
  }
  .footer-main .foter-bottom .copyright p {
    font-size: 12px;
  }
  .content-area .header-title {
    padding: 20px;
  }
  .drupal-col-5,
  .drupal-col-7 {
    width: 100%;
  }
  .service-grid .row-block {
    flex-wrap: wrap;
  }
  .service-grid .row-block .block {
    width: 100%;
  }
  .service-grid .row-block .block h2 {
    font-size: 20px;
  }
  .service-grid .row-block .block .text {
    padding: 15px;
  }
  .service-grid .row-block:nth-child(odd) {
    border-radius: 0;
  }
  .content-area .header-title {
    padding: 20px;
  }
  .studies-slider .owl-dots {
    margin-top: 60px;
  }
  .with-img-section .content-part-left {
    padding: 0;
  }

  /**thanks page here**/
  .drupal-thanks-sec {
    padding: 0 0 30px;
  }
  .content-wrap li {
    flex: 0 0 100%;
  }
  .drupal-about-more {
    padding: 30px 0;
    margin: 30px 0;
  }
  .drupal-col-3 {
    width: 100%;
  }
}
.loading-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000085;
  z-index: 9999;
}
.loader {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
}
.loading-spinning-bubbles {
  position: relative;
  margin: auto;
}
.loading-spinning-bubbles .bubble-container {
  position: absolute;
  top: calc(50% - 10px / 2);
  left: calc(50% - 10px / 2);
  transform-origin: -150% 50%;
}
.loading-spinning-bubbles .bubble-container .bubble {
  background: #6e6ef0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: bubble 1s infinite;
  animation-delay: inherit;
}
.loading-spinning-bubbles .bubble-container:nth-of-type(0n + 1) {
  transform: translateX(200%) rotate(-90deg);
  animation-delay: -1.5s;
}
.loading-spinning-bubbles .bubble-container:nth-of-type(0n + 2) {
  transform: translateX(200%) rotate(-45deg);
  animation-delay: -1.375s;
}
.loading-spinning-bubbles .bubble-container:nth-of-type(0n + 3) {
  transform: translateX(200%);
  animation-delay: -1.25s;
}
.loading-spinning-bubbles .bubble-container:nth-of-type(0n + 4) {
  transform: translateX(200%) rotate(45deg);
  animation-delay: -1.125s;
}
.loading-spinning-bubbles .bubble-container:nth-of-type(0n + 5) {
  transform: translateX(200%) rotate(90deg);
  animation-delay: -1s;
}
.loading-spinning-bubbles .bubble-container:nth-of-type(0n + 6) {
  transform: translateX(200%) rotate(135deg);
  animation-delay: -875ms;
}
.loading-spinning-bubbles .bubble-container:nth-of-type(0n + 7) {
  transform: translateX(200%) rotate(180deg);
  animation-delay: -0.75s;
}
.loading-spinning-bubbles .bubble-container:nth-of-type(0n + 8) {
  transform: translateX(200%) rotate(225deg);
  animation-delay: -625ms;
}

/* added new css start*/
.primary-navigation .mega-menu > li:last-child {
  margin-left: 15px;
}

.primary-navigation .mega-menu .mega-indicator {
	display: inline-block;
	margin-right: -3px;
}
/* added new css end*/

@keyframes bubble {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/** **/

.comanform .input-text:focus-visible,
.comanform .input-text:focus {
  outline: none;
  border-color: #ffff;
}

.drupal-thanks .drupal-col-6:nth-child(1) {
  text-align: center;
}
.drupal-thanks .drupal-row {
  align-items: start;
}

@media (max-width: 767px) {
  .caststudy-slider {
    padding: 60px 0 90px;
  }
  .studies-slider .owl-dots {
    margin-top: 0 !important;
    position: absolute;
    margin: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
@media (max-width: 500px) {
  .studies-slider .owl-dots {
    bottom: -90%;
  }
}
@media (min-width: 501px) and (max-width: 767px) {
  .studies-slider .owl-dots {
    bottom: -70%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .studies-slider .owl-dots {
    margin-top: 0 !important;
    position: absolute;
    margin: 0;
    bottom: -64%;
    left: 0;
    right: 0;
    margin: auto;
  }
  .caststudy-slider {
    padding: 60px 0 90px;
  }
}
